import BlogCategory from '../blog-category';
import Style from './style';
import { withRouter } from 'react-router-dom';
import { formateTimeByTimeZone } from 'utils/datetime.js';
import { Text } from 'components/base';
import React from 'react';
import { useIntl } from 'react-intl';

interface Blog {
  blogId: number;
  digest: string;
  status: string;
  title: string;
  creatorNo: string;
  createdAt: number;
  categoryName: string;
  coverUrl: string;
  shopName: string;
  avatar: string;
}

const BlogListItem = (props) => {
  const blog: Blog = props?.blog;
  const { history, shopLogoUrl, shopName } = props;

  const logoUrl = blog?.avatar || shopLogoUrl;
  const onToBlogDetail = (id) => {
    history.push(`/blogs/detail/${id}`);
  };
  const intl = useIntl();

  return (
    <Style>
      <div
        className='blogs-item'
        onClick={() => {
          onToBlogDetail(blog.blogId);
        }}
      >
        <div className='blog-title-image' style={{ backgroundImage: `url(${blog.coverUrl})` }} />
        <div className='blog-intro-container'>
          <div>
            <div className='blog-intro-title'>
              <Text size={10}>{blog.title}</Text>
            </div>
            <div className='blog-intro-content'>{blog.digest}</div>
            <div className='time-container'>
              <svg width={16} height={16} fill='#969799'>
                <use xlinkHref='#iconicon_loading1' />
              </svg>

              <span className='time-text'>{formateTimeByTimeZone(new Date(blog.createdAt))}</span>
            </div>
            <div className='category-container'>{blog.categoryName && <span>{blog.categoryName}</span>}</div>
          </div>
          <div className='author-container'>
            {logoUrl ? (
              <div className='shop-logo-container'>
                <img src={logoUrl} />
              </div>
            ) : (
              <svg width={24} height={24} fill='#000'>
                <use xlinkHref='#iconic-bokemoren' />
              </svg>
            )}
            <span className='admin-text'>{blog?.creatorNo}</span>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default withRouter(BlogListItem);
