import Style from './style';
import React, { useEffect, useState } from 'react';
import { Swiper } from 'zent';
import { Text } from 'components/base';
import shopApi from 'api/shop';
import { formateTimeByTimeZone } from 'utils/datetime.js';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

const BlogRecommend = ({ shopLogoUrl, history }) => {
  const [recommendList, setRecommendList] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    shopApi.getHotBlogs().then((list) => {
      const newList = list.map((item) => {
        const search = item?.hotBgUrl?.split('?')[1];
        if (!search) return;
        const urlParams = new URLSearchParams(search);
        const width = Number(urlParams.get('width'));
        const height = Number(urlParams.get('height'));
        const isOverHeight = width / height < 1280 / 460;
        return { ...item, isOverHeight };
      });
      setRecommendList(newList);
    });
  }, []);

  const onToBlogDetail = (id) => {
    history.push(`/blogs/detail/${id}`);
  };

  return (
    <Style>
      {recommendList?.length > 0 && (
        <Swiper className='swiper-recommend' dotsColor='#2654FF' dotsSize='small' autoplay>
          {recommendList.map((item, index) => {
            return (
              <div
                className='recommend-item'
                key={index}
                onClick={() => {
                  onToBlogDetail(item?.blogId);
                }}
              >
                <img
                  src={item?.hotBgUrl}
                  alt='recommend img'
                  className={cn('recommend-banner-img', { 'is-over-height': !item?.isOverHeight })}
                />
                <div className='commend-blog-description'>
                  <div className='blog-title'>
                    <Text size={7}>{item?.title}</Text>
                  </div>
                  <div className='blog-digest'>{item?.digest}</div>
                  <div className='create-time-container'>
                    <span className='logo-container'>
                      {item?.avatar || shopLogoUrl ? (
                        <img src={item?.avatar || shopLogoUrl} alt='store logo' />
                      ) : (
                        <svg width={24} height={24} fill='#000'>
                          <use xlinkHref='#iconic-bokemoren' />
                        </svg>
                      )}
                    </span>
                    <span className='admin-text'>
                      {item?.creatorNo ||
                        intl.formatMessage({
                          defaultMessage: '官方客服',
                          id: '81058b291f23454592eff88f99e0a7e8',
                        })}
                    </span>
                    <span className='create-time'>{formateTimeByTimeZone(new Date(item?.createdAt))}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Swiper>
      )}
    </Style>
  );
};

export default withRouter(BlogRecommend);
